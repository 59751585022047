.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 100px;
  height: 100px;
}

.loading-text {
  color: var(--white); /* Use the white color from your root */
  font-size: 20px; /* Adjust font size as needed */
  animation: blink 0.5s ease-in-out infinite both;
}

@keyframes blink {
  0%, 50% {
    color: #d3d3d3;
  }
  51%, 100% {
    color: var(--white);
  }
}