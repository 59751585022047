.content {
    margin-top: -50px;
    background: grey;
    height: 1000px;
  }
  
  .btn {
    position: sticky;
    width: 50px;
    height: 50px;
    background: red;
    top: calc(100vh - 55px);
    z-index: 10;
  }
  
  .footer {
    position: sticky;
    width: 100%;
    height: 40px;
    background: blue;
    top: 0;
  }